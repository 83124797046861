// 手机号码验证 - 08开头的 9-13位
export const validatePhoneNumber = phone => {
  if (!phone) {
    return false
  }
  return /^08\d{7,11}$/.test(phone)
}

// 密码验证 - 8位数字密码
export const validatePassword = password => {
  if (!password) {
    return false
  }
  return /^\d{8}$/.test(password)
}

// 数字验证码验证 - 4位数字
export const validateVerifyCode = verifyCode => {
  if (!verifyCode) {
    return false
  }
  return /^\d{4}$/.test(verifyCode)
}

// 银行帐号验证 - 数字,输入长度限制大于等于10-小于等于20位
export const validateBankAccount = bankAccount => {
  if (!bankAccount) {
    return false
  }
  return /^\d{10,20}$/.test(bankAccount)
}

// 邮箱验证
export const validateEmail = email => {
  if (!email) {
    return false
  }
  return /@.*\./.test(email)
}

// 紧急联系人号码验证 - 08开头9-13位或628开头10-14位
export const validateContactPhone = phone => {
  if (validatePhoneNumber(phone)) {
    return true
  } else {
    return /^628\d{7,11}$/.test(phone)
  }
}
